
import { ISenffPreProposal } from "@/interfaces/senffProposal";

import SenffComplementInfo from "@/views/SenffComplementInfo.vue";
import { invoiceAlunoStore, invoiceStore, senffProposalStore } from "../store";
import Mixins from "@/Mixins.vue";
import _ from "lodash";
import { Component } from "vue-property-decorator";
import BookCover from "../components/BookCover.vue";
import DialogComponent from "../components/DialogComponent.vue";
import LogoImg from "../components/LogoImg.vue";
import ProductDetails from "../components/ProductDetails.vue";
import ProgressBar from "../components/ProgressBar.vue";
import TimerComponent from "../components/TimerComponent.vue";
import Cartao from "./PaymentForms/Cartao.vue";
import Coupon from "./PaymentForms/Coupon.vue";
import Pix from "./PaymentForms/Pix.vue";
import PrivacyData from "./PrivacyData.vue";

@Component({
  components: {
    LogoImg,
    Cartao,
    Pix,
    DialogComponent,
    Coupon,
    BookCover,
    ProgressBar,
    ProductDetails,
    TimerComponent,
    PrivacyData,
    SenffComplementInfo,
  },
})
export default class Payments extends Mixins {
  items: any[] = [];

  preProposalIsLoading = false;
  tokenSenffIsLoading = false;
  statusSenffIsLoading = false;

  confirmBtn = false;
  openDialog2 = false;
  openDialog = false;
  openDialogSenff = false;
  openDialogTokenSenff = false;
  approvedSenff = false;
  receivedToken = false;
  btnSenffText = "Fazer Cartão Senff";
  haveSenff = false;
  complementSubmit = false;
  havePreProposal = false;
  completedPreProposal = false;
  tokenSenff = null;
  proposalId = null;
  masterProposalId = null;
  openDialogComplementInfo = false;
  openDialogStatusSenff = false;
  statusProposalDialogStatus = "";
  statusProposalDialogMessage = "";
  snackbar = false;
  timeoutSnackbar = 5000;
  textSnack = "";
  canHaveSenff = false;
  currentTab = 0;
  couponCode = "";

  clickedClipboardCopy = false;
  loadingFormasPagamento = false;

  aceites = {
    TermoUso: false,
    PoliticaPrivacidade: false,
  };

  paymentOptions: any = {
    "1": "Cartão de Crédito",
    "8": "Outros",
    "11": "PIX",
  };

  selectedFormPayment = null;

  headers = [
    { width: 50, text: "", value: "product.capa", align: "center" },
    { text: "Produto", value: "product.item_name", align: "center" },
    { text: "Quantidade", value: "quantity", align: "center" },
  ];

  get formasPagamento() {
    return invoiceStore.formasPagamento;
  }

  get invoiceAluno() {
    return invoiceAlunoStore.invoiceAluno;
  }

  get fields() {
    if (!this.invoiceAluno) return;

    const items = _.get(this.lastInvoice, "items", []);
    const fields = {
      Nome: this.invoiceAluno.full_name,
      CPF: this.formatCPF(this.invoiceAluno.cpf),
      Unidade: _.get(this.lastInvoice.company, "nome_fantasia"),
      Endereço: `${this.invoiceAluno.street}, ${this.invoiceAluno.street_number} - Bairro ${this.invoiceAluno.neighborhood}`,
      Telefone: this.formatPhone(this.invoiceAluno.phone),
      "Itens do pedido": _.join(
        _.map(
          items,
          (i) =>
            `${String(i.quantity).padStart(2, "0")} - ${i.product.item_name}`
        ),
        ", "
      ),
    };
    return fields;
  }

  get lastInvoice() {
    return invoiceStore.lastInvoice;
  }

  get loggedEmail() {
    return localStorage.getItem("invoice-login");
  }

  async loadFormasPagamentoCoupon({ confirmed, couponCode }) {
    if (confirmed) {
      this.couponCode = couponCode;

      const { confirmed, data } = await invoiceStore.getFormasPagamento({
        preInvoiceId: this.lastInvoice.id!,
        couponCode: couponCode,
      });

      if (confirmed) {
        this.$swal({
          title: "Cupom aplicado!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          returnFocus: true,
        });
      } else {
        this.$swal({
          title: "Erro ao aplicar cupom",
          text: data,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          returnFocus: true,
        });
      }
    }
  }

  get preProposal() {
    return senffProposalStore.senffPreProposalResponse;
  }
  // onChangeTab(tab: number) {
  //   if (tab === 1 && this.invoiceAluno?.company?.franchising_id === 1) {
  //     this.$swal({
  //       icon: "error",
  //       title: "Atenção!",
  //       html: `
  //         <b>No momento, o pagamento via PIX está
  //         temporariamente indisponível. Nossa equipe
  //         já está trabalhando para resolver o problema,
  //         e em breve essa opção estará disponível
  //         novamente.</b>
  //         <br />
  //         <br />
  //         Enquanto isso, você pode seguir com suas compras
  //         utilizando o cartão de crédito normalmente.
  //         <br />
  //         <br />
  //         Agradecemos pela compreensão.
  //       `,
  //       showCancelButton: false,
  //       timer: undefined,
  //       timerProgressBar: undefined,
  //     });
  //   }
  // }

  continueBtn() {
    this.openDialog = false;
    this.openDialog2 = true;
  }

  async sendSenffPreProposal() {
    this.preProposalIsLoading = true;
    let senff_pre_proposal: ISenffPreProposal = {
      //cnpj: this.invoiceAluno.company.cnpj,
      company_id: this.invoiceAluno.company_id,
      name: this.invoiceAluno.full_name,
      cpf: this.invoiceAluno.cpf,
      telephoneDDD: this.invoiceAluno.phone.substring(0, 2),
      telephoneNumber: this.invoiceAluno.phone.substring(2),
      email: this.invoiceAluno.email,
    };

    let payload = {
      pre_proposal: senff_pre_proposal,
      invoice_aluno_id: this.invoiceAluno.id,
    };

    const response = await senffProposalStore.submitSenffPreProposal(payload);

    if (response.confirmed) {
      this.proposalId = response.data.senff_proposal.proposal_id;
      this.masterProposalId = response.data.senff_proposal.id;
      this.openDialogSenff = false;
      this.openDialogTokenSenff = true;
      this.receivedToken = true;
    } else {
      console.error(response.data);
      this.snackbar = true;
      this.textSnack = "";
      for (let error in response.data) {
        for (let messageError of response.data[error]) {
          this.textSnack += messageError + "\n";
        }
      }
    }
    this.preProposalIsLoading = false;
  }

  async analysisPreProposal() {
    this.tokenSenffIsLoading = true;
    if (!this.tokenSenff) {
      let input: any = this.$refs.inputToken;
      input.validate(true);
      return;
    }

    const response = await senffProposalStore.analysisPreProposal({
      proposalId: this.proposalId,
      proposalToken: this.tokenSenff,
      invoice_aluno_id: this.invoiceAluno.id,
    });

    if (response.confirmed) {
      this.openDialogTokenSenff = false;
      this.openDialogComplementInfo = true;
      this.completedPreProposal = true;
    } else {
      console.error(response.data);
      this.snackbar = true;
      this.textSnack = "";
      for (let error in response.data) {
        for (let messageError of response.data[error]) {
          this.textSnack += messageError + "\n";
        }
      }
    }
    this.tokenSenffIsLoading = false;
  }

  async showStatusMessage(status: { message: string; status: string }) {
    this.statusProposalDialogStatus = status.status;
    this.statusProposalDialogMessage = status.message;
    this.complementSubmit = true;
    this.openDialogStatusSenff = true;
  }

  async showErrorSnackbar(error: { snackbar: boolean; message: string }) {
    this.textSnack = error.message;
    this.snackbar = error.snackbar;
  }

  async clipboardCopy(value: string) {
    // Altera URL sem redirect
    history.pushState({}, null, value);

    if (!!navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(value);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = value;
      textArea.style.position = "fixed";
      // textArea.style.left = "-999999px";
      // textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      await new Promise<void>((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }

    this.$swal({
      icon: "success",
      text: "Link copiado para a área de transferência!",
      timer: 2000,
      timerProgressBar: true,
    });
  }

  confirmBtnLastModal() {
    this.openDialog2 = false;
    if (this.canHaveSenff) {
      this.handleDialogVisibility(true);
    }
  }

  handleDialogSenffComplementVisibility(value: boolean) {
    this.openDialogComplementInfo = value;
  }

  handleDialogVisibility(value: boolean) {
    if (this.haveSenff) {
      return;
    }

    if (this.complementSubmit || this.approvedSenff) {
      this.openDialogStatusSenff = value;
    } else if (this.completedPreProposal) {
      this.openDialogComplementInfo = value;
    } else if (this.receivedToken) {
      this.openDialogTokenSenff = value;
    } else {
      this.openDialogSenff = value;
    }
  }

  checkStatus(status: string) {
    if (status == "PROCESSING" || status == "DERIVATIVE_MANDATORY") {
      return {
        status: "Em Análise",
        message:
          "Suas informações foram enviadas, a senff entrará em contato com você via whatsapp para comprovação das informações, após isso você poderá entrar em contato com a escola para solicitação do cartão",
      };
    }

    if (status == "APPROVED" || status == "APPROVE_MANDATORY") {
      return {
        status: "Aprovado",
        message:
          "Seu cadastro foi aprovado, você já pode solicitar o Cartão Senff para sua escola",
      };
    }

    if (status == "DISAPPROVED") {
      return {
        status: "Reprovado",
        message: "Infelizmente seu cadastro para o Cartão Senff foi reprovado",
      };
    }

    if (status == "PENDING_CARD_LINK") {
      return {
        status: "Pendente de Vinculação",
        message:
          "Seu cadastro foi aprovado, você já pode solicitar o Cartão Senff para sua escola",
      };
    }

    if (status == "DERIVATIVE") {
      return {
        status: "Derivado",
        message:
          "Suas informações foram enviadas, porém será necessário uma análise manual delas, assim que a análise for concluída, a senff entrará em contato com você via whatsapp",
      };
    }
  }

  async getStatusProposal() {
    this.statusSenffIsLoading = true;
    const response = await senffProposalStore.getProposals(
      this.invoiceAluno.id
    );
    if (response) {
      let proposal: any = _.last(response);
      let status = this.checkStatus(proposal.status);
      this.statusProposalDialogStatus = status.status;
      this.statusProposalDialogMessage = status.message;
    }
    this.statusSenffIsLoading = false;
  }

  async mounted() {
    if (!this.lastInvoice || !this.lastInvoice.id) {
      this.closeSection();
      this.$swal({
        icon: "error",
        title: "Pedido não encontrado",
        text: `
          Não conseguimos localizar nenhum pedido para ${this.invoiceAluno.full_name}
        `,
      });
    } else {
      if (this.lastInvoice.status_id === 1) {
        this.openDialog = true;
      }

      this.loadingFormasPagamento = true;
      await invoiceStore.getFormasPagamento({
        preInvoiceId: this.lastInvoice.id,
        // couponCode: "CLUB12345", // DEBUG TEST
      });
      this.loadingFormasPagamento = false;
      this.items = this.lastInvoice.items;
      if (this.lastInvoice.status_id === 1) {
        // this.openDialog = true;
      }

      // Inicialmente somente 3 unidades podem ter acesso ao cartão senff, adicionar array de ids quando for liberado o uso
      // essas unidades são ITAJAI, ARARAS, CIANORTE - [1324, 1284, 1795]
      const companiesWithSenff = [1795];

      if (companiesWithSenff.includes(this.invoiceAluno.company_id)) {
        this.canHaveSenff = true;
        let proposals: any = await senffProposalStore.getProposals(
          this.invoiceAluno.id
        );
        if (proposals.length > 0) {
          let last_proposal: any = _.last(proposals);
          if (
            last_proposal.status == "APPROVED" ||
            last_proposal.status == "APPROVE_MANDATORY" ||
            last_proposal.status == "PENDING_CARD_LINK" ||
            last_proposal.status == "LINKED_CARD"
          ) {
            this.approvedSenff = true;
            let status = this.checkStatus(last_proposal.status);
            this.statusProposalDialogStatus = status.status;
            this.statusProposalDialogMessage = status.message;
            if (
              last_proposal.proposal_completed &&
              last_proposal.status == "LINKED_CARD"
            ) {
              this.haveSenff = true;
            }
          } else {
            if (!last_proposal.proposal_completed) {
              this.proposalId = last_proposal.proposal_id;
              this.masterProposalId = last_proposal.id;
              if (last_proposal.pre_proposal_completed) {
                if (last_proposal.status != "DISAPPROVED") {
                  this.havePreProposal = true;
                  this.completedPreProposal = true;
                  this.btnSenffText = "Continuar Cadastro Senff";
                }
              }
              if (last_proposal.complement_submit) {
                this.complementSubmit = true;
                this.btnSenffText = "Consultar Status Senff";
                let status = this.checkStatus(last_proposal.status);
                this.statusProposalDialogStatus = status.status;
                this.statusProposalDialogMessage = status.message;
              }
            }
          }
        }
      }
    }
  }
}
