import { API_USER_TOKEN, apiUrl } from "@/env";
import axios, { AxiosRequestConfig } from "axios";
import { ICreditCard } from "./interfaces/creditCard";
import { ISenffPreProposal, ISenffProposalComplementaryInfo } from "@/interfaces/senffProposal"

function axiosConfig(): AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${API_USER_TOKEN}`,
    },
    timeout: 30000,
  };
}

const api = {
  async submitSenffPreProposal(payload: { pre_proposal: ISenffPreProposal, invoice_aluno_id: number, }) {
    const config = axiosConfig()
    config.params = {
      invoice_aluno_id: payload.invoice_aluno_id
    }
    return axios.post(`${apiUrl}/senff-proposal/send_pre_proposal/`, payload.pre_proposal, config)
  },
  async submitSenffComplementInfo(payload: { proposal: ISenffProposalComplementaryInfo, senff_proposal_id: number, }) {
    const config = axiosConfig()
    config.params = {
      senff_proposal_id: payload.senff_proposal_id
    }
    return axios.post(`${apiUrl}/senff-proposal/send_proposal_complementary_info/`, payload.proposal, config)
  },
  async analysisPreProposal(proposalId: string, proposalToken: string, senff_proposal_id: number) {
    const config = axiosConfig()
    config.timeout = 120000
    let payload = {
      proposalId: proposalId,
      proposalToken: proposalToken
    }
    return axios.post(`${apiUrl}/senff-proposal/execute_pre_proposal/${senff_proposal_id}/`, payload, config)
  },
  async getSenffProposals(invoice_aluno_id: number) {
    const config = axiosConfig()
    return axios.get(`${apiUrl}/senff-proposal/get_proposals/${invoice_aluno_id}/`, config)
  },
  async getStatusSenffProposal(cnpj: string, cpf?: string) {
    const config = axiosConfig()
    if (cpf) {
      config.params = {
        cpf: cpf
      }
    }
    return axios.get(`${apiUrl}/senff-proposal/get_proposals_in_senff/${cnpj}/`, config)
  },
  async getCities() {
    const config = axiosConfig()
    return axios.get(`${apiUrl}/cities/`, config);
  },
  async submitPayment(payload: {
    creditCard: ICreditCard;
    couponCode?: string;
  }) {
    const config = axiosConfig();
    config.params = { coupon_code: payload.couponCode };
    return axios.post(
      `${apiUrl}/pre-invoices/payment-credit-card/`,
      payload.creditCard,
      config
    );
  },
  async getFormasPagamento(payload: {
    preInvoiceId?: number;
    company_id?: number;
    couponCode?: string;
    c015_ids?: object;
  }) {
    const resource = payload.preInvoiceId
      ? `${payload.preInvoiceId}/`
      : `${payload.company_id}/${JSON.stringify(payload.c015_ids)}/`;
    const config = axiosConfig();
    config.params = { coupon_code: payload.couponCode };
    return axios.get(
      `${apiUrl}/pre-invoices/formas-pagamento/${resource}`,
      config
    );
  },
  async verifyCoupon(payload: { couponCode: string; companyId: number }) {
    const config = axiosConfig();
    config.params = {
      coupon_code: payload.couponCode,
      company_id: payload.companyId,
    };
    return axios.get(`${apiUrl}/coupons/validate/`, config);
  },
  async getProducts(payload: { franchising_id?: number }) {
    const config = axiosConfig();
    config.params = {
      available: true,
      franchising_id: payload.franchising_id,
      show_material_nivelamento: false,
      allow_invoice_aluno: false,
    };
    return axios.get(`${apiUrl}/products/`, config);
  },
  async getInvoiceAluno(payload: { email: string; password: string }) {
    const config = axiosConfig();
    config.params = payload;
    return axios.get(`${apiUrl}/invoice-alunos/loja/`, config);
  },
};

export default api;
