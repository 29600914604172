import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import api from "@/api"
import _ from 'lodash'
import { ISenffPreProposal, ISenffProposalComplementaryInfo } from '@/interfaces/senffProposal'


@Module({ name: "senffProposal" })
export default class SenffProposalModule extends VuexModule {

  senffProposal: any
  senffPreProposalResponse: any

  @Mutation
  setSenffProposal(payload: any) {
    this.senffProposal = payload
  }

  @Mutation
  setSenffPreProposalResponse(payload: any){
    this.senffPreProposalResponse = payload
  }

  @Action
  async submitSenffPreProposal(payload: { pre_proposal: ISenffPreProposal, invoice_aluno_id: number,}) {
    try {
      const response = await api.submitSenffPreProposal(payload);

      if (response.data && response.status >= 200 && response.status < 300) {
        this.setSenffPreProposalResponse(response.data);
        return { confirmed: true, data: response.data };
      } 
    } catch (e) {
      const error: any = e;
      console.log(error.response.data.detail)
      return { confirmed: false, data: error.response.data.detail || "Não foi possível enviar sua pré análise" };
    }
  }

  @Action
  async submitSenffComplementInfo(payload: { proposal: ISenffProposalComplementaryInfo, senff_proposal_id: number,}) {
    try {
      const response = await api.submitSenffComplementInfo(payload);

      if (response.data && response.status >= 200 && response.status < 300) {
        this.setSenffPreProposalResponse(response.data);
        return { confirmed: true, data: response.data };
      }
    } catch (e) {
      const error: any = e;
      return { confirmed: false, data: error.response.data.detail || "Não foi possível enviar suas informações" };
    }
  }

  @Action
  async analysisPreProposal(payload: { proposalId: string, proposalToken: string, invoice_aluno_id: number}){
    try {
      const response = await api.analysisPreProposal(payload.proposalId, payload.proposalToken, payload.invoice_aluno_id)
      if (response.data) {
        return { confirmed: true, data: response.data };
      } 
    } catch (e) {
      const error: any = e;
      return { confirmed: false, data: error.response.data.detail || "Não foi possível enviar sua pré análise" };
    }
  }

  @Action
  async getProposals(invoice_aluno_id: number){
    try {
      const response = await api.getSenffProposals(invoice_aluno_id)
      if (response.data && response.status >= 200 && response.status < 300) {
        return response.data
      } 
    } catch (e) {
      const error: any = e;
      return error.response.data.detail || "Não foi possível consultar as análises";
    }
  }

  @Action
  async getStatusProposals(payload: {cnpj: string, cpf?: string}){
    try {
      const response = await api.getStatusSenffProposal(payload.cnpj, payload.cpf)
      if (response.data && response.status >= 200 && response.status < 300) {
        return response.data
      } 
    } catch (e) {
      const error: any = e;
      return error.response.data.detail || "Não foi possível consultar os status das análises";
    }
  }
}