
import { addDays, endOfDay, format, parse, subDays } from "date-fns";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

@Component
export default class DateComponent extends Vue {
  @Prop({ default: "Selecionar data" }) readonly label;
  @Prop({ default: "Selecionar data" }) readonly placeholder;

  // Não mexer
  @Prop({ default: null }) value;
  @Prop({ default: () => [] }) readonly rules;
  @Prop({ default: true }) readonly clearable;
  @Prop({ default: false }) readonly dense;
  @Prop({ default: false }) readonly disabled;
  @Prop({ default: false }) readonly singleLine;
  @Prop({ default: "" }) readonly customClass;
  @Prop({ default: "auto" }) readonly hideDetails;
  @Prop({ default: false }) readonly range;
  @Prop({ default: false }) readonly filled;
  @Prop({ default: false }) readonly rounded;
  @Prop({ default: 30 }) readonly height;
  @Prop({ default: false }) readonly outlined;
  @Prop({ default: false }) readonly endOfDay;
  @Prop({ default: "DATE" }) readonly activePicker;
  @Prop({ default: "" }) readonly hint;
  @Prop({ default: false }) readonly persistentHint;
  @Prop({ default: "mdi-calendar-range" }) readonly prependInnerIcon;
  @Prop({ default: "" }) readonly customStyle;

  // Date format to return as "dd/MM/yyyy"
  @Prop({ default: "" }) readonly format;

  // Opções das props: "yesterday", "today", "tomorrow", Date as "yyyy-MM-dd"
  @Prop({ default: null }) readonly allowFrom;
  @Prop({ default: null }) readonly allowTo;

  @Prop({ default: false }) readonly businessDaysOnly;

  public showMenu = false;
  public modelValue: any = null;

  get today() {
    return new Date().toISOString().slice(0, 10);
  }

  get parsedDate() {
    if (!this.modelValue) return;
    return this.endOfDay
      ? endOfDay(parse(this.modelValue, "yyyy-MM-dd", new Date()))
      : parse(this.modelValue, "yyyy-MM-dd", new Date());
  }

  get rangeParsedDate() {
    if (!this.modelValue || this.modelValue.length !== 2) return;
    this.modelValue = _.orderBy(this.modelValue);
    return new Array(
      parse(this.modelValue[0], "yyyy-MM-dd", new Date()),
      this.endOfDay
        ? endOfDay(parse(this.modelValue[1], "yyyy-MM-dd", new Date()))
        : parse(this.modelValue[1], "yyyy-MM-dd", new Date())
    );
  }

  get formatedDate() {
    if (!this.parsedDate) return;
    return format(this.parsedDate, this.useFormat || "dd/MM/yyyy");
  }

  get rangeFormatedDate() {
    if (!this.modelValue || this.modelValue.length !== 2) return;
    return new Array(
      format(this.rangeParsedDate![0], this.useFormat || "dd/MM/yyyy"),
      format(this.rangeParsedDate![1], this.useFormat || "dd/MM/yyyy")
    );
  }

  get viewFormatDate() {
    if (!this.parsedDate) return;
    return format(this.parsedDate, "dd/MM/yyyy");
  }

  get rangeViewFormatDate() {
    if (!this.modelValue || this.modelValue.length !== 2) return;
    return `${format(this.rangeParsedDate![0], "dd/MM/yyyy")} - ${format(
      this.rangeParsedDate![1],
      "dd/MM/yyyy"
    )}`;
  }

  get allowFromParsed() {
    switch (true) {
      case _.isDate(this.allowFrom):
        return this.allowFrom;
      case this.allowFrom === "today":
        return parse(this.today, "yyyy-MM-dd", new Date());
      case this.allowFrom === "tomorrow":
        return addDays(parse(this.today, "yyyy-MM-dd", new Date()), 1);
      case this.allowFrom === "yesterday":
        return subDays(parse(this.today, "yyyy-MM-dd", new Date()), 1);
      case !!this.allowFrom && this.allowFrom.length >= 10:
        return parse(
          this.allowFrom.slice(0, 10),
          this.useFormat.slice(0, 10) || "yyyy-MM-dd",
          new Date()
        );
      default:
        return this.allowFrom;
    }
  }

  get allowToParsed() {
    switch (true) {
      case _.isDate(this.allowTo):
        return this.allowTo;
      case this.allowTo === "today":
        return parse(this.today, "yyyy-MM-dd", new Date());
      case this.allowTo === "tomorrow":
        return addDays(parse(this.today, "yyyy-MM-dd", new Date()), 1);
      case this.allowTo === "yesterday":
        return subDays(parse(this.today, "yyyy-MM-dd", new Date()), 1);
      case !!this.allowTo && this.allowTo.length >= 10:
        return parse(
          this.allowTo.slice(0, 10),
          this.useFormat || "yyyy-MM-dd",
          new Date()
        );
      default:
        return this.allowTo;
    }
  }

  get useFormat() {
    if (this.format === "ISO") return "yyyy-MM-dd'T'HH:mm:ss.SSS'z'";
    if (this.format === "ISO2") return "yyyy-MM-dd'T'HH:mm:ss";
    return this.format;
  }

  public allowedDates(date: string) {
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());
    const rules = [];

    if (this.businessDaysOnly) {
      rules.push(_.includes([1, 2, 3, 4, 5], parsedDate.getDay()));
    }

    if (!!this.allowFromParsed) {
      rules.push(parsedDate >= this.allowFromParsed);
    }

    if (!!this.allowToParsed) {
      rules.push(parsedDate <= this.allowToParsed);
    }

    return _.every(rules);
  }

  @Watch("value", { immediate: true })
  onUpdateValue(val) {
    if (!!val) {
      if (this.range) {
        if (val.length === 2) {
          this.modelValue = new Array(
            format(
              parse(val[0], this.useFormat || "yyyy-MM-dd", new Date()),
              "yyyy-MM-dd"
            ),
            format(
              parse(val[1], this.useFormat || "yyyy-MM-dd", new Date()),
              "yyyy-MM-dd"
            )
          );
        }
      } else {
        this.modelValue = format(
          parse(val, this.useFormat || "yyyy-MM-dd", new Date()),
          "yyyy-MM-dd"
        );
      }
    } else {
      this.modelValue = null;
    }
  }

  @Emit("input")
  public onInput(val) {
    this.showMenu = false;
    if (this.format) {
      return this.formatedDate;
    }
    return this.endOfDay
      ? endOfDay(parse(val, this.useFormat || "yyyy-MM-dd", new Date()))
      : parse(val, this.useFormat || "yyyy-MM-dd", new Date());
  }

  @Emit("input")
  public rangeOnInput(val) {
    if (val.length === 2) {
      this.showMenu = false;
      if (this.format) {
        return this.rangeFormatedDate;
      }
      return val;
    }
  }

  @Emit("input")
  public clear() {
    return null;
  }
}
